import {
  Stack,
  Container,
  Box,
  Flex,
  Text,
  Heading,
  SimpleGrid,
} from "@chakra-ui/react";
import AppContext from "../hooks/AppContext";
import { useContext } from "react";
import { CustomButton } from "../common/CustomButton";

export default function StatsGridWithImage() {
  const { showFeatures, setShowFeatures } = useContext(AppContext);

  return (
    <>
      {showFeatures && (
        <Box bg={"gray.800"} position={"relative"}>
          <Flex
            flex={1}
            zIndex={0}
            display={{ base: "none", lg: "flex" }}
            backgroundImage="url('/templates/stats-grid-with-image.png')"
            backgroundSize={"cover"}
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            position={"absolute"}
            width={"100%"}
            insetY={0}
            right={0}
          >
            <Flex
              bgGradient={"linear(to-r, gray.800 10%, transparent)"}
              w={"full"}
              h={"full"}
            />
          </Flex>
          <Container maxW={"3xl"} zIndex={10} position={"relative"}>
            <Stack direction={{ base: "column", lg: "row" }}>
              <Stack
                flex={3}
                color={"gray.400"}
                justify={{ lg: "center" }}
                py={{ base: 4, md: 10, xl: 20 }}
              >
                <Box mb={{ base: 8, md: 10 }}>
                  <Text
                    fontFamily={"heading"}
                    fontWeight={700}
                    textTransform={"uppercase"}
                    mb={3}
                    fontSize={"xl"}
                    color={"gray.500"}
                  >
                    Technology Rundown
                  </Text>
                  <Heading
                    color={"white"}
                    mb={5}
                    fontSize={{ base: "3xl", md: "5xl" }}
                  >
                    Only the best. For you.
                  </Heading>
                  <Text fontSize={"xl"} color={"gray.400"}>
                    DD Caddy™ helps you take your golf to the next level. With
                    personalized insights based on your game, practice
                    recommendations for score slashing and machine learning
                    based visual aids, DD Caddy is the best smart golf caddy
                    application in the market.
                  </Text>
                </Box>

                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                  {stats.map((stat) => (
                    <Box key={stat.title}>
                      <Text
                        fontFamily={"heading"}
                        fontSize={"3xl"}
                        color={"white"}
                        mb={3}
                      >
                        {stat.title}
                      </Text>
                      <Text fontSize={"xl"} color={"gray.400"}>
                        {stat.content}
                      </Text>
                    </Box>
                  ))}
                </SimpleGrid>
                <CustomButton
                  onClick={() => setShowFeatures(!showFeatures)}
                  type="primary"
                >
                  Back
                </CustomButton>
              </Stack>
            </Stack>
          </Container>
        </Box>
      )}
    </>
  );
}

const StatsText = ({ children }) => (
  <Text as={"span"} fontWeight={700} color={"white"}>
    {children}
  </Text>
);

const stats = [
  {
    title: "6",
    content: (
      <>
        Average <StatsText>drop in score</StatsText> by our users in the first
        60 days
      </>
    ),
  },
  {
    title: "300+",
    content: (
      <>
        <StatsText>Rounds of golf</StatsText> played while testing this product
      </>
    ),
  },
  {
    title: "24/7",
    content: (
      <>
        Personalized <StatsText>analytics</StatsText> right at your finger tips
      </>
    ),
  },
  {
    title: "15K",
    content: (
      <>
        <StatsText>Happy Users</StatsText> all over the world (and counting)
      </>
    ),
  },
];

import Head from "next/head";
import { Box, Heading, Container, Text, Stack } from "@chakra-ui/react";
import { CallToActionButtons } from "../common/CallToActionButtons";
import AppContext from "../hooks/AppContext";
import { useContext } from "react";

export default function CallToActionWithAnnotation() {
  const { showFeatures, setShowFeatures } = useContext(AppContext);

  return (
    <>
      <Head>
        <link
          href="https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap"
          rel="stylesheet"
        />
      </Head>

      {!showFeatures && (
        <Container maxW={"3xl"}>
          <Stack
            as={Box}
            textAlign={"center"}
            spacing={{ base: 12, md: 14 }}
            py={{ base: 20, md: 36 }}
          >
            <Heading
              fontWeight={600}
              fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
              lineHeight={"110%"}
            >
              Don't just note GIR%
              <br />
              <Text as={"span"} color={"green.400"}>
                Get an all-in-one caddy
              </Text>
            </Heading>
            <Text color={"gray.500"}>
              DD Caddy stands for Data Driven Caddy. It is an advanced
              all-in-one smart caddy designed with the amateur golfer in mind.
              Take charge of your game with personalized insights and
              improvement recommendations. <br /> <br />
              Built by golfers, for golfers.
            </Text>
            <CallToActionButtons
              learnMoreFunc={() => setShowFeatures(!showFeatures)}
            />
          </Stack>
        </Container>
      )}
    </>
  );
}

import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import CallToActionWithAnnotation from "./sections/hero";
import StatsGridWithImage from "./sections/features";
import { ContextProvider } from "./hooks/AppContext";

function App() {
  return (
    <ChakraProvider>
      <ContextProvider>
        <div className="App">
          <CallToActionWithAnnotation />
          <StatsGridWithImage />
        </div>
      </ContextProvider>
    </ChakraProvider>
  );
}

export default App;

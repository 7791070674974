import { Stack, Button } from "@chakra-ui/react";

export function CustomButton({ type, onClick, children }) {
  return (
    <Stack
      direction={"column"}
      spacing={3}
      align={"center"}
      alignSelf={"center"}
      position={"relative"}
    >
      {type === "primary" ? (
        <Button
          colorScheme={"green"}
          size={"md"}
          bg={"green.400"}
          rounded={"full"}
          px={6}
          onClick={onClick}
          _hover={{
            bg: "green.500",
          }}
        >
          {children}
        </Button>
      ) : (
        <Button
          onClick={onClick}
          variant={"link"}
          colorScheme={"blue"}
          size={"sm"}
        >
          {children}
        </Button>
      )}
    </Stack>
  );
}
